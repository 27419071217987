import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { setTransactions } from "../redux/slices/transactionSlice";
import axiosInstance from "../api/axiosInstance";
import { toast } from "react-toastify";
import Cookies from "js-cookie";
import "../assets/css/DashboardStyles.css";
import TransactionCard from "../components/TransactionCard";
import MapModal from "../components/MapModal";
import EditExpenseModal from "../components/EditExpenseModal";
import ExpenseFilters from "../components/ExpenseFilters";
import "../assets/css/ExpenseFiltersStyles.css";
import { FaFilter } from "react-icons/fa"; // Import filter icon
import moment from "moment"; // Import moment for date manipulation

const Dashboard = () => {
  const dispatch = useDispatch();
  const transactions = useSelector((state) => state.transactions.transactions);
  const [searchTerm, setSearchTerm] = useState("");
  const [showAddExpenseModal, setShowAddExpenseModal] = useState(false);
  const [showEditExpenseModal, setShowEditExpenseModal] = useState(false);
  const [selectedTransaction, setSelectedTransaction] = useState(null);
  const [currencies, setCurrencies] = useState([]);
  const [tags, setTags] = useState([]);
  const [filters, setFilters] = useState({
    fromDate: moment().subtract(30, "days").format("YYYY-MM-DD"),
    toDate: moment().format("YYYY-MM-DD"),
    selectedTags: [],
  }); // State to manage filters with default last 30 days
  const [formData, setFormData] = useState({
    amount: "",
    currency: "1", // Default value for INR
    timestamp: new Date().toISOString().slice(0, 16),
    tag: "",
    description: "",
  });
  const [newTag, setNewTag] = useState("");
  const [showNewTagField, setShowNewTagField] = useState(false);
  const [filteredTransactions, setFilteredTransactions] = useState([]);
  const [showFilters, setShowFilters] = useState(false); // State to toggle filters visibility
  const [location, setLocation] = useState({ latitude: null, longitude: null });

  useEffect(() => {
    const authToken = Cookies.get("authToken");
    if (authToken) {
      axiosInstance.defaults.headers.common["Authorization"] = `Bearer ${authToken}`;
    }

    fetchTransactions(filters);
    fetchCurrencies();
    fetchTags();
  }, [dispatch]);

  const fetchTransactions = async (filters) => {
    try {
      const response = await axiosInstance.get("/expenses", {
        params: {
          fromDate: filters.fromDate,
          toDate: filters.toDate,
          badges: filters.selectedTags.join(","), // Pass badges as comma-separated string
        },
      });
      if (response.data.success) {
        dispatch(setTransactions(response.data.data));
        setFilteredTransactions(response.data.data);
      } else {
        toast.error("Failed to fetch transactions.");
      }
    } catch (error) {
      toast.error("Failed to fetch transactions.");
    }
  };

  const getLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setLocation({
            latitude: position.coords.latitude,
            longitude: position.coords.longitude,
          });
        },
        (error) => {
          toast.error("Unable to fetch location. Please enable location services.");
        }
      );
    } else {
      toast.error("Geolocation is not supported by this browser.");
    }
  };

  const fetchTags = async () => {
    try {
      const response = await axiosInstance.get("/expenses/badges");
      if (response.data.success) {
        setTags(response.data.data);
        if (response.data.data.length > 0) {
          // Set the default tag to the one with the lowest ID
          setFormData((prevData) => ({
            ...prevData,
            tag: response.data.data.reduce((min, tag) => (tag.id < min.id ? tag : min)).badge_id,
          }));
        }
      } else {
        toast.error("Failed to fetch tags.");
      }
    } catch (error) {
      toast.error("Failed to fetch tags.");
    }
  };

  const fetchCurrencies = async () => {
    try {
      const response = await axiosInstance.get("/expenses/currencies/");
      if (response.data.success) {
        setCurrencies(response.data.data);
      } else {
        toast.error("Failed to fetch currencies.");
      }
    } catch (error) {
      toast.error("Failed to fetch currencies.");
    }
  };

  const handleAddExpense = () => {
    getLocation(); // Get the user's location when adding an expense
    setShowAddExpenseModal(true);
  };

  const closeAddExpenseModal = () => {
    setShowAddExpenseModal(false);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    // setFormData({ ...formData, [name]: value });
    setFormData({ ...formData, [name]: name === "tag" ? parseInt(value) : value });
  };

  const handleSaveExpense = async (e) => {
    e.preventDefault();
    try {
      const response = await axiosInstance.post("/expenses/", {
        amount: formData.amount,
        currency_id: formData.currency,
        timestamp: formData.timestamp,
        description: formData.description,
        badge_id: parseInt(formData.tag), // Ensure the badge_id is sent as an integer
        latitude: location.latitude,
        longitude: location.longitude,
      });
      if (response.data.success) {
        const newTransaction = response.data.data;
        console.log(response.data); // Log the new transaction to check its properties

        // Ensure the new transaction has all required properties
        if (newTransaction && newTransaction.description && newTransaction.amount) {
          toast.success("Expense saved successfully!");
          setShowAddExpenseModal(false);
          setFormData({
            amount: "",
            currency: "1",
            timestamp: new Date().toISOString().slice(0, 16),
            tag: tags.length > 0 ? tags[0].id : "",
            description: "",
          });
          dispatch(setTransactions([...transactions, newTransaction]));
        } else {
          toast.error("Invalid transaction data received.");
        }
      } else {
        toast.error("Failed to save expense.");
      }
    } catch (error) {
      toast.error("Failed to save expense.");
    }
  };

  const handleDeleteTransaction = async (transaction) => {
    try {
      const response = await axiosInstance.delete(`/expenses/`, {
        data: { txn_id: transaction.transaction_id },
      });
      if (response.data.success) {
        toast.success("Expense deleted successfully!");
        // Filter out the deleted transaction from the transactions list
        dispatch
          ? dispatch(setTransactions(transactions.filter((txn) => txn.transaction_id !== transaction.transaction_id)))
          : setFilteredTransactions(
              filteredTransactions.filter((txn) => txn.transaction_id !== transaction.transaction_id)
            );
      } else {
        toast.error("Failed to delete expense.");
      }
    } catch (error) {
      toast.error("Failed to delete expense.");
    }
  };

  const handleCreateTag = async () => {
    try {
      const response = await axiosInstance.post("/expenses/badges", { name: newTag });
      if (response.data.success) {
        toast.success("Tag created successfully!");
        setShowNewTagField(false);
        setNewTag("");
        // Refresh the tags list
        const updatedTagsResponse = await axiosInstance.get("/expenses/badges");
        if (updatedTagsResponse.data.success) {
          setTags(updatedTagsResponse.data.data);
        }
      } else {
        toast.error("Failed to create tag.");
      }
    } catch (error) {
      toast.error("Failed to create tag.");
    }
  };

  // Add state for showing location modal and coordinates
  const [showMapModal, setShowMapModal] = useState(false);
  const [coordinates, setCoordinates] = useState(null);

  const handleShowLocation = (transaction) => {
    if (transaction.latitude && transaction.longitude) {
      setCoordinates({
        latitude: parseFloat(transaction.latitude),
        longitude: parseFloat(transaction.longitude),
      }); // Set the coordinates from transaction data
      setShowMapModal(true); // Show the map modal
    } else {
      toast.error("Location not available for this transaction.");
    }
  };

  const closeMapModal = () => {
    setShowMapModal(false);
  };

  const handleEditTransaction = (transaction) => {
    setSelectedTransaction(transaction);
    setShowEditExpenseModal(true);
  };

  const closeEditExpenseModal = () => {
    setShowEditExpenseModal(false);
    setSelectedTransaction(null);
  };

  useEffect(() => {
    setFilteredTransactions(transactions);
  }, [transactions]);

  const handleFilterChange = (newFilters) => {
    setFilters(newFilters);
    fetchTransactions(newFilters); // Fetch transactions based on updated filters
  };

  const toggleFilters = () => {
    setShowFilters((prev) => !prev);
  };

  return (
    <div className="dashboard-container">
      {/* Expense Filters - Show or Hide */}
      {showFilters && <ExpenseFilters tags={tags} onFilterChange={handleFilterChange} />}

      {/* Desktop View - Add Expense Box and Transaction List */}
      <div className="row d-none d-lg-flex">
        {/* Transactions List */}
        <div className="col-lg-8 transaction-list-container">
          <div className="search-bar mb-3 mt-3">
            <input
              type="text"
              className="form-control mr-2"
              placeholder="Search transactions..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
            <button className="btn btn-outline-primary" onClick={toggleFilters}>
              <FaFilter />
            </button>
          </div>
          <div className="transaction-list">
            {filteredTransactions.length === 0 ? (
              <div className="text-center">
                <p>Welcome to Dollar Diary. Please add your first expense</p>
              </div>
            ) : (
              filteredTransactions
                .filter((txn) => txn.description.toLowerCase().includes(searchTerm.toLowerCase()))
                .map((transaction) => {
                  // Find the badge name based on badge_id
                  const tag = tags.find((tag) => tag.badge_id === transaction.badge_id);
                  const badgeName = tag ? tag.name : "";

                  return (
                    <TransactionCard
                      key={transaction.transaction_id}
                      transaction={{ ...transaction, badge_name: badgeName }}
                      onEdit={() => handleEditTransaction(transaction)}
                      onDelete={() => handleDeleteTransaction(transaction)}
                      onShowLocation={() => handleShowLocation(transaction)}
                    />
                  );
                })
            )}
          </div>
        </div>
        {/* Add Expense Box */}
        <div className="col-lg-4 add-expense-box-container">
          <div className="add-expense-box">
            <h5>Add Expense</h5>
            <form onSubmit={handleSaveExpense}>
              <div className="form-group">
                <label>Amount</label>
                <div className="input-group">
                  <select
                    name="currency"
                    className="currency-selector form-control"
                    value={formData.currency}
                    onChange={handleInputChange}
                    required
                  >
                    {currencies.map((currency) => (
                      <option key={currency.currency_id} value={currency.currency_id}>
                        {currency.code}
                      </option>
                    ))}
                  </select>
                  <input
                    type="number"
                    name="amount"
                    className="form-control"
                    placeholder="Amount"
                    value={formData.amount}
                    onChange={handleInputChange}
                    required
                  />
                </div>
              </div>
              <div className="form-group">
                <label>
                  Tag{" "}
                  <button
                    type="button"
                    className={`btn btn-sm ml-2 ${showNewTagField ? "btn-danger" : "btn-primary"}`}
                    onClick={() => setShowNewTagField(!showNewTagField)}
                  >
                    {showNewTagField ? "-" : "+"}
                  </button>
                </label>
                <select
                  name="tag"
                  className="form-control"
                  value={formData.tag}
                  onChange={handleInputChange} // Handle tag selection change
                  required
                >
                  {tags.map((tag) => (
                    <option key={tag.badge_id} value={tag.badge_id}>
                      {tag.name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="form-group">
                <label>Date & Time</label>
                <input
                  type="datetime-local"
                  name="timestamp"
                  className="form-control"
                  value={formData.timestamp}
                  onChange={handleInputChange}
                />
              </div>
              <div className="form-group">
                <label>Description</label>
                <textarea
                  name="description"
                  className="form-control"
                  placeholder="Description"
                  value={formData.description}
                  onChange={handleInputChange}
                ></textarea>
              </div>
              <button type="submit" className="btn btn-primary w-100 mt-3">
                Save
              </button>
              {showNewTagField && (
                <div className="form-group">
                  <input
                    type="text"
                    name="newTag"
                    className="form-control mt-4"
                    placeholder="New Tag Name"
                    value={newTag}
                    onChange={(e) => setNewTag(e.target.value)}
                  />
                  <button type="button" className="btn btn-primary w-100 mt-3" onClick={handleCreateTag}>
                    Create Tag
                  </button>
                </div>
              )}
            </form>
          </div>
        </div>
      </div>

      {/* Mobile View - Transaction List */}
      <div className="d-lg-none">
        <div className="search-bar mb-3 mt-3">
          <input
            type="text"
            className="form-control"
            placeholder="Search transactions..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
          <button className="btn btn-outline-primary" onClick={toggleFilters}>
            <FaFilter />
          </button>
        </div>
        <div className="transaction-list">
          {filteredTransactions.length === 0 ? (
            <div className="text-center">
              <p>Welcome to Dollar Diary. Please add your first expense</p>
            </div>
          ) : (
            filteredTransactions
              .filter((txn) => txn.description.toLowerCase().includes(searchTerm.toLowerCase()))
              .map((transaction) => {
                // Find the badge name based on badge_id
                const tag = tags.find((tag) => tag.badge_id === transaction.badge_id);
                const badgeName = tag ? tag.name : "";

                return (
                  <TransactionCard
                    key={transaction.transaction_id}
                    transaction={{ ...transaction, badge_name: badgeName }}
                    onEdit={() => handleEditTransaction(transaction)}
                    onDelete={() => handleDeleteTransaction(transaction)}
                    onShowLocation={() => handleShowLocation(transaction)}
                  />
                );
              })
          )}
        </div>
      </div>

      {/* Mobile View - Add Expense Modal */}
      {showAddExpenseModal && (
        <div className="add-expense-modal">
          <div className="modal-content">
            <button className="close-modal" onClick={closeAddExpenseModal}>
              &times;
            </button>
            <h5>Add Expense</h5>
            <form onSubmit={handleSaveExpense}>
              <div className="form-group">
                <label>Amount</label>
                <div className="input-group">
                  <select
                    name="currency"
                    className="currency-selector form-control"
                    value={formData.currency}
                    onChange={handleInputChange}
                  >
                    {currencies.map((currency) => (
                      <option key={currency.currency_id} value={currency.currency_id}>
                        {currency.code}
                      </option>
                    ))}
                  </select>
                  <input
                    type="number"
                    name="amount"
                    className="form-control"
                    placeholder="Amount"
                    value={formData.amount}
                    onChange={handleInputChange}
                  />
                </div>
              </div>
              <div className="form-group">
                <label>
                  Tag{" "}
                  <button
                    type="button"
                    className={`btn btn-sm ml-2 ${showNewTagField ? "btn-danger" : "btn-primary"}`}
                    onClick={() => setShowNewTagField(!showNewTagField)}
                  >
                    {showNewTagField ? "-" : "+"}
                  </button>
                </label>
                <select
                  name="tag"
                  className="form-control"
                  value={formData.tag}
                  onChange={(e) => setFormData({ ...formData, tag: parseInt(e.target.value) })}
                >
                  {tags.map((tag) => (
                    <option key={tag.badge_id} value={tag.badge_id}>
                      {tag.name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="form-group">
                <label>Date & Time</label>
                <input
                  type="datetime-local"
                  name="timestamp"
                  className="form-control"
                  value={formData.timestamp}
                  onChange={handleInputChange}
                />
              </div>
              <div className="form-group">
                <label>Description</label>
                <textarea
                  name="description"
                  className="form-control"
                  placeholder="Description"
                  value={formData.description}
                  onChange={handleInputChange}
                ></textarea>
              </div>
              <button type="submit" className="btn btn-primary w-100 mt-3">
                Save
              </button>
              {showNewTagField && (
                <div className="form-group">
                  <input
                    type="text"
                    name="newTag"
                    className="form-control mt-3"
                    placeholder="New Tag Name"
                    value={newTag}
                    onChange={(e) => setNewTag(e.target.value)}
                  />
                  <button type="button" className="btn btn-primary w-100 mt-2" onClick={handleCreateTag}>
                    Create Tag
                  </button>
                </div>
              )}
            </form>
          </div>
        </div>
      )}

      {/* Mobile View - Add Expense Button (Sticky at Bottom) */}
      <div className="sticky-add-button d-lg-none">
        <button className="btn btn-primary w-75" onClick={handleAddExpense}>
          Add Expense
        </button>
      </div>

      {/* Edit Expense */}
      {showEditExpenseModal && selectedTransaction && (
        <EditExpenseModal
          transaction={selectedTransaction}
          currencies={currencies}
          tags={tags}
          closeEditModal={closeEditExpenseModal}
          onSave={(updatedTransaction) => {
            // Update the transactions state with the edited transaction
            const updatedTransactions = transactions.map((txn) =>
              txn.transaction_id === updatedTransaction.transaction_id ? updatedTransaction : txn
            );
            dispatch(setTransactions(updatedTransactions));
          }}
        />
      )}

      {/* Map Modal */}
      {showMapModal && <MapModal coordinates={coordinates} closeMapModal={closeMapModal} />}
    </div>
  );
};

export default Dashboard;
