import React from "react";
import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api";
import "../assets/css/MapModalStyles.css";

const MapModal = ({ coordinates, closeMapModal }) => {
  const mapContainerStyle = {
    width: "100%",
    height: "400px",
  };

  // Check if coordinates are valid and finite
  const isValidCoordinates =
    coordinates &&
    typeof coordinates.latitude === "number" &&
    typeof coordinates.longitude === "number" &&
    isFinite(coordinates.latitude) &&
    isFinite(coordinates.longitude);

  const center = isValidCoordinates
    ? {
        lat: coordinates.latitude,
        lng: coordinates.longitude,
      }
    : { lat: 0, lng: 0 }; // Default center if coordinates are invalid

  return (
    <div className="map-modal">
      <div className="map-modal-content">
        <button className="close-modal" onClick={closeMapModal}>
          &times;
        </button>
        {isValidCoordinates ? (
          <LoadScript googleMapsApiKey={process.env.REACT_APP_MAPS_API_KEY}>
            <GoogleMap mapContainerStyle={mapContainerStyle} center={center} zoom={15}>
              <Marker position={center} />
            </GoogleMap>
          </LoadScript>
        ) : (
          <p>Invalid coordinates</p>
        )}
      </div>
    </div>
  );
};

export default MapModal;
