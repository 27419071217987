import React from "react";
import { FaEdit, FaTrash, FaMapMarkerAlt } from "react-icons/fa";
import "../assets/css/TransactionCardStyles.css";

const TransactionCard = ({ transaction, onEdit, onDelete, onShowLocation }) => {
  return (
    <div className="card mb-3 w-100">
      <div className="card-body">
        <div className="row">
          <div className="col-8">
            <div className="d-flex align-items-center">
              <h5 className="card-title mb-0 mr-2">
                {transaction.symbol} {parseFloat(transaction.amount).toFixed(2)}
              </h5>&nbsp;
              {transaction.badge_name && (
                <span className="badge badge-primary ml-2">{transaction.badge_name}</span>
              )}
            </div>
            <p className="card-text mt-2">{transaction.description}</p>
          </div>
          <div className="col-4 text-right">
            <p className="card-text">
              <small className="text-muted">
                {new Date(transaction.timestamp).toLocaleString("en-US", {
                  year: "numeric",
                  month: "short",
                  day: "numeric",
                  hour: "2-digit",
                  minute: "2-digit",
                })}
              </small>
            </p>
            <div className="btn-group" role="group">
              <button className="btn btn-secondary btn-sm" onClick={onEdit}>
                <FaEdit />
              </button>
              <button className="btn btn-danger btn-sm" onClick={onDelete}>
                <FaTrash />
              </button>
              <button className="btn btn-info btn-sm" onClick={onShowLocation}>
                <FaMapMarkerAlt />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TransactionCard;
