import React from 'react';
import { Bar } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';

// Register components required for Bar Chart
ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const BarChart = ({ data }) => {
  // Prepare labels and dataset from props
  const labels = data.map(item => item.interval);
  const expenseData = data.map(item => item.totalExpense);

  const chartData = {
    labels: labels,
    datasets: [
      {
        label: 'Expenses',
        data: expenseData,
        backgroundColor: '#351A6E',
      },
    ],
  };

  return <Bar data={chartData} />;
};

export default BarChart;
