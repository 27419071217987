import React from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import LoginPage from './pages/LoginPage';
import Dashboard from './pages/Dashboard';
import ReportsPage from './pages/ReportsPage';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ForgotPasswordPage from './pages/ForgotPasswordPage';
import CreateAccountPage from './pages/CreateAccountPage';
import Navbar from './components/Navbar';
import PrivateRoute from './components/PrivateRoute';

const AppContent = () => {
  const location = useLocation();

  // Define the paths where the Navbar should not be displayed
  const noNavbarPaths = ['/', '/create-account', '/forgot-password'];

  return (
    <>
      <ToastContainer />
      {/* Conditionally render the Navbar */}
      {!noNavbarPaths.includes(location.pathname) && <Navbar />}
      {/* Show page-container only when noNavbarPaths */}
      <div className={!noNavbarPaths.includes(location.pathname) && "page-container"}>
        <Routes>
          <Route path="/" element={<LoginPage />} />
          <Route path="/dashboard" element={<PrivateRoute><Dashboard /></PrivateRoute>} />
          <Route path="/reports" element={<PrivateRoute><ReportsPage /></PrivateRoute>} />
          <Route path="/forgot-password" element={<ForgotPasswordPage />} />
          <Route path="/create-account" element={<CreateAccountPage />} />
        </Routes>
      </div>
    </>
  );
};

const App = () => {
  return (
    <Router>
      <AppContent />
    </Router>
  );
};

export default App;
