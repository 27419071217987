import React, { useState } from "react";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "../assets/css/LoginPage.css";
import axiosInstance from "../api/axiosInstance";

const CreateAccountPage = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [rePassword, setRePassword] = useState("");
  const navigate = useNavigate();

  const handleCreateAccount = async (e) => {
    e.preventDefault();

    if (password !== rePassword) {
      toast.error("Passwords do not match!");
      return;
    }

    try {
      const response = await axiosInstance.post("/users/", {
        name,
        email,
        password,
      });

      if (response.data.success) {
        toast.success("Account created successfully! You can now log in.");
        navigate("/");
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error("Account creation failed. Please try again.");
    }
  };

  return (
    <div className="login-container">
      <div className="login-box">
        <h3 className="text-center mb-4">Create New Account</h3>
        <form onSubmit={handleCreateAccount}>
          <div className="mb-3">
            <label className="form-label">Name</label>
            <input
              type="text"
              className="form-control"
              value={name}
              onChange={(e) => setName(e.target.value)}
              required
            />
          </div>
          <div className="mb-3">
            <label className="form-label">Email address</label>
            <input
              type="email"
              className="form-control"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </div>
          <div className="mb-3">
            <label className="form-label">Password</label>
            <input
              type="password"
              className="form-control"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
          </div>
          <div className="mb-3">
            <label className="form-label">Re-enter Password</label>
            <input
              type="password"
              className="form-control"
              value={rePassword}
              onChange={(e) => setRePassword(e.target.value)}
              required
            />
          </div>
          <button type="submit" className="btn btn-primary w-100 mb-3">
            Create Account
          </button>
        </form>
        <div className="d-flex justify-content-between">
          <a href="/" className="text-decoration-none text-primary">
            Already have an account?
          </a>
        </div>
      </div>
    </div>
  );
};

export default CreateAccountPage;
