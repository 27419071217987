import React from "react";
import { Pie } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";

// Register components required for Pie Chart
ChartJS.register(ArcElement, Tooltip, Legend);

const PieChart = ({ data }) => {
  const labels = data.map((item) => item.tag);
  const expenseData = data.map((item) => item.totalExpense);

  const chartData = {
    labels: labels,
    datasets: [
      {
        data: expenseData,
        backgroundColor: [
          "#FF6384",
          "#36A2EB",
          "#FFCE56",
          "#4BC0C0",
          "#9966FF",
          "#FF9F40",
          "#FF6384",
          "#36A2EB",
          "#FFCE56",
          "#4BC0C0",
        ],
        // backgroundColor: ["#351A6E", "#4c1a7d", "#6e1a8b"], // Different shades of primary color for each tag
      },
    ],
  };

  return <Pie data={chartData} />;
};

export default PieChart;
