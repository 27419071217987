import React, { useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Cookies from 'js-cookie';
import { toast } from 'react-toastify';
import '../assets/css/AppStyles.css';
import { clearUser } from '../redux/slices/userSlice';
import { FaUserCircle, FaCaretDown } from 'react-icons/fa';
import logo from '../assets/images/dd_logo.png';

const Navbar = () => {
  const location = useLocation();
  const userInfo = useSelector((state) => state.user);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const handleLogout = () => {
    Cookies.remove('authToken'); // Remove token from cookies
    dispatch(clearUser()); // Clear user data from Redux
    toast.success('Successfully logged out!');
    navigate('/'); // Redirect to login page
  };

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <nav className="navbar navbar-expand-lg navbar-custom">
      <div className="container-fluid">
        {/* Logo */}
        <a className="navbar-brand order-lg-1" href="/">
          <img src={logo} alt="Dollar Diary" className="navbar-logo" />
        </a>

        {/* Dashboard and Reports Links */}
        <div className="collapse navbar-collapse order-lg-2" id="navbarMenu">
          <ul className="navbar-nav ml-3">
            <li className={`nav-item ${location.pathname === '/dashboard' ? 'active' : ''}`}>
              <Link to="/dashboard" className="nav-link">Dashboard</Link>
            </li>
            <li className={`nav-item ${location.pathname === '/reports' ? 'active' : ''}`}>
              <Link to="/reports" className="nav-link">Reports</Link>
            </li>
          </ul>
        </div>

        {/* Profile Section */}
        <div className="profile-info order-lg-3 d-none d-lg-flex">
          <div className="profile-dropdown">
            <FaUserCircle size={30} />
            <span className="profile-name">{userInfo?.name}</span>
            <FaCaretDown className="dropdown-icon" />
            <div className="dropdown-menu">
              <button className="dropdown-item" onClick={handleLogout}>Sign Out</button>
            </div>
          </div>
        </div>

        {/* Hamburger Icon for Collapsible Menu */}
        <button
          className="navbar-toggler order-lg-4"
          type="button"
          aria-expanded={isMenuOpen}
          aria-label="Toggle navigation"
          onClick={toggleMenu}
        >
          <span className="navbar-toggler-icon"></span>
        </button>

        {/* Mobile Menu */}
        {isMenuOpen && (
          <div className="mobile-menu mt-2">
            <ul className="navbar-nav">
              <li className={`nav-item ${location.pathname === '/dashboard' ? 'active' : ''}`}>
                <Link to="/dashboard" className="nav-link" onClick={toggleMenu}>Dashboard</Link>
              </li>
              <li className={`nav-item ${location.pathname === '/reports' ? 'active' : ''}`}>
                <Link to="/reports" className="nav-link" onClick={toggleMenu}>Reports</Link>
              </li>
              <li className="nav-item">
                <button className="nav-link" onClick={handleLogout}>Sign Out</button>
              </li>
            </ul>
          </div>
        )}
      </div>
    </nav>
  );
};

export default Navbar;
