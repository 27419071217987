import React, { useState, useEffect } from "react";
import moment from "moment";
import Select from "react-select";
import "../assets/css/ExpenseFiltersStyles.css";

const ExpenseFilters = ({ tags, onFilterChange }) => {
  const [fromDate, setFromDate] = useState(moment().subtract(30, "days").format("YYYY-MM-DD"));
  const [toDate, setToDate] = useState(moment().format("YYYY-MM-DD"));
  const [selectedTags, setSelectedTags] = useState([]);

  useEffect(() => {
    onFilterChange({
      fromDate: fromDate || "",
      toDate: toDate || "",
      selectedTags: selectedTags.map((tag) => tag.value),
    });
  }, [fromDate, toDate, selectedTags, onFilterChange]); // Automatically call onFilterChange when the filter values change

  return (
    <div className="expense-filters-container">
      <div className="filter-item">
        <label htmlFor="from-date">From Date</label>
        <input
          type="date"
          id="from-date"
          value={fromDate || ""}
          onChange={(e) => setFromDate(e.target.value)} // Set value only
          className="form-control"
        />
      </div>
      <div className="filter-item">
        <label htmlFor="to-date">To Date</label>
        <input
          type="date"
          id="to-date"
          value={toDate || ""}
          onChange={(e) => setToDate(e.target.value)} // Set value only
          className="form-control"
        />
      </div>
      <div className="filter-item">
        <label htmlFor="tags">Tags</label>
        <Select
          isMulti
          id="tags"
          options={tags.map((tag) => ({ value: tag.badge_id, label: tag.name }))}
          value={selectedTags}
          onChange={(tags) => setSelectedTags(tags || [])} // Set selected tags value only
          classNamePrefix="select"
        />
      </div>
    </div>
  );
};

export default ExpenseFilters;
