// src/pages/LoginPage.js
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { setUser } from "../redux/slices/userSlice";
import { toast } from "react-toastify";
import "../assets/css/LoginPage.css";
import { useNavigate } from "react-router-dom";
import axiosInstance from "../api/axiosInstance";
import Cookies from "js-cookie";
import logo from '../assets/images/dd_logo.png';

const LoginPage = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      const response = await axiosInstance.post("/users/login", { email, password });
      if (response.data.success) {
        Cookies.set("authToken", response.data.authToken, { expires: 7 }); // Store token in cookies for 7 days
        toast.success(response.data.message);

        // Dispatch setUser to save user data in Redux
        dispatch(setUser({ name: response.data.name, email: response.data.email }));

        navigate("/dashboard"); // Redirect to dashboard
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error("Login failed. Please try again.");
    }
  };

  return (
    <div className="login-container">
      <div className="login-box">
        <div className="text-center mb-4">
          <img src={logo} alt="Dollar Diary" className="login-logo" />
        </div>
        {/* <h3 className="text-center mb-4">Log in</h3> */}
        <form onSubmit={handleLogin}>
          <div className="mb-3">
            <label className="form-label">Email address</label>
            <input
              type="email"
              className="form-control"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </div>
          <div className="mb-3">
            <label className="form-label">Password</label>
            <input
              type="password"
              className="form-control"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
          </div>
          <button type="submit" className="btn btn-primary w-100 mb-3">
            Log in
          </button>
        </form>
        <div className="d-flex justify-content-between">
          <a href="/forgot-password" className="text-decoration-none text-primary">
            Forgot password?
          </a>
          <a href="/create-account" className="text-decoration-none text-primary">
            Create Account
          </a>
        </div>
      </div>
    </div>
  );
};

export default LoginPage;
