// Updated ForgotPasswordPage.js

import React, { useState } from "react";
import "../assets/css/LoginPage.css";
import axiosInstance from "../api/axiosInstance";
import logo from '../assets/images/dd_logo.png';
import { toast } from "react-toastify";

const ForgotPasswordPage = () => {
  const [email, setEmail] = useState("");

  const handleForgotPassword = async (e) => {
    e.preventDefault();
    try {
      const response = await axiosInstance.post("/users/forgot-password", { email });
      if (response.data.success) {
        toast.success("Password reset link sent to your email!");
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error("Failed to send password reset link. Please try again.");
    }
  };

  return (
    <div className="login-container">
      <div className="login-box">
        <div className="text-center mb-4">
          <img src={logo} alt="Dollar Diary" className="login-logo" />
        </div>
        {/* <h3 className="text-center mb-4">Forgot Password</h3> */}
        <form onSubmit={handleForgotPassword}>
          <div className="mb-3">
            <label className="form-label">Email address</label>
            <input
              type="email"
              className="form-control"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </div>
          <button type="submit" className="btn btn-primary w-100 mb-3">
            Reset Password
          </button>
        </form>
        <div className="d-flex justify-content-between">
          <a href="/" className="text-decoration-none text-primary">
            Already have an account?
          </a>
        </div>
      </div>
    </div>
  );
};

export default ForgotPasswordPage;
