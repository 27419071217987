// Updated ReportsPage.js

import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { setBadges } from "../redux/slices/transactionSlice";
import { toast } from "react-toastify";
import axiosInstance from "../api/axiosInstance";
import ExpenseFilters from "../components/ExpenseFilters";
import BarChart from "../components/Reports/BarChart";
import PieChart from "../components/Reports/PieChart";
import "../assets/css/ReportsPageStyles.css";
import moment from "moment";

const ReportsPage = () => {
  const dispatch = useDispatch();
  const badges = useSelector((state) => state.transactions.badges);

  // Set initial filter values based on Redux store
  const [filters, setFilters] = useState({
    fromDate: moment().subtract(30, "days").format("YYYY-MM-DD"),
    toDate: moment().format("YYYY-MM-DD"),
    selectedTags: [],
    filterType: "monthly",
  });

  const [barChartData, setBarChartData] = useState([]);
  const [pieChartData, setPieChartData] = useState([]);

  useEffect(() => {
    const fetchReportsData = async () => {
      try {
        const badgeResponse = await axiosInstance.get("/expenses/badges");
        if (badgeResponse.data.success) {
          dispatch(setBadges(badgeResponse.data.data));
        }

        const reportsResponse = await axiosInstance.get("/expenses/reports", {
          params: {
            fromDate: filters.fromDate,
            toDate: filters.toDate,
            badges: filters.selectedTags.join(","),
            filterType: filters.filterType,
          },
        });

        if (reportsResponse.data.success) {
          setBarChartData(reportsResponse.data.barChartData);
          setPieChartData(reportsResponse.data.pieChartData);
        }
      } catch (error) {
        toast.error("Failed to load reports data.");
      }
    };

    fetchReportsData();
  }, [filters, dispatch]);

  const handleFilterChange = (newFilters) => {
    setFilters(newFilters);
  };

  return (
    <div className="container reports-page-container">
      <h2 className="reports-title text-center mb-4">Reports Overview</h2>

      {/* Expense Filters Component */}
      <ExpenseFilters tags={badges} onFilterChange={handleFilterChange} />

      {/* Filter Type Dropdown */}
      <div className="filter-type-container mb-4">
        <label htmlFor="filter-type" className="me-2">
          Filter Type:
        </label>
        <select
          id="filter-type"
          className="form-select"
          value={filters.filterType}
          onChange={(e) => setFilters({ ...filters, filterType: e.target.value })}
        >
          <option value="monthly">Monthly</option>
          <option value="weekly">Weekly</option>
          <option value="yearly">Yearly</option>
        </select>
      </div>

      {/* Charts Section */}
      {/* <div className="row">
        <div className="col-md-6 mb-4">
          <h5 className="text-center">Expenses Overview</h5>
          <div className="chart-box">
            <BarChart data={barChartData} />
          </div>
        </div>
        <div className="col-md-6 mb-4">
          <h5 className="text-center">Expenses Distribution by Tags</h5>
          <div className="chart-box">
            <PieChart data={pieChartData} />
          </div>
        </div>
      </div> */}
      <div className="reports-container">
        <div className="chart-item">
          <h5 className="text-center">Expenses Overview</h5>
          <div className="chart-box">
            {/* IF data is avaialble then only show the chart */}
            {barChartData.length > 0 && <BarChart data={barChartData} />}
          </div>
        </div>
        <div className="chart-item">
          <h5 className="text-center">Expenses Distribution by Tags</h5>
          <div className="chart-box">
            {/* IF data is avaialble then only show the chart */}
            {pieChartData.length > 0 && <PieChart data={pieChartData} />}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReportsPage;
