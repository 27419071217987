// src/components/EditExpenseModal.js
import React, { useState, useEffect } from "react";
import axiosInstance from "../api/axiosInstance";
import { toast } from "react-toastify";
import "../assets/css/EditExpenseModal.css";
import moment from "moment";

const EditExpenseModal = ({ transaction, currencies, tags, closeEditModal, onSave }) => {
  const [formData, setFormData] = useState({
    amount: "",
    currency: "",
    timestamp: new Date().toISOString().slice(0, 16),
    tag: "",
    description: "",
  });

  useEffect(() => {
    if (transaction && transaction.transaction_id) {
      const fetchTransaction = async () => {
        try {
          const response = await axiosInstance.get(`/expenses/txn/${transaction.transaction_id}`);
          if (response.data.success) {
            const fetchedTransaction = response.data.data[0];
            setFormData({
              amount: parseFloat(fetchedTransaction.amount),
              currency: `${fetchedTransaction.currency_id}`,
              timestamp: moment(fetchedTransaction.timestamp).format("YYYY-MM-DDTHH:mm"),
              tag: fetchedTransaction.badge_id,
              description: fetchedTransaction.description,
            });
          } else {
            toast.error("Failed to fetch transaction data.");
          }
        } catch (error) {
          toast.error("Failed to fetch transaction data.");
        }
      };

      fetchTransaction();
    }
  }, [transaction]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: name === "tag" ? parseInt(value) : value });
  };

  const handleSaveExpense = async (e) => {
    e.preventDefault();
    try {
      const response = await axiosInstance.put(`/expenses/`, {
        txn_id: transaction.transaction_id,
        amount: formData.amount,
        currency_id: formData.currency,
        timestamp: formData.timestamp,
        description: formData.description,
        badge_id: parseInt(formData.tag),
      });
      if (response.data.success) {
        toast.success("Expense updated successfully!");
        console.log(response.data);
        onSave(response.data.data);
        closeEditModal();
      } else {
        toast.error("Failed to update expense.");
      }
    } catch (error) {
      toast.error("Failed to update expense.");
    }
  };

  return (
    <div className="edit-expense-modal">
      <div className="modal-content">
        <button className="close-modal" onClick={closeEditModal}>
          &times;
        </button>
        <h5>Edit Expense</h5>
        <form onSubmit={handleSaveExpense}>
          <div className="form-group">
            <label>Amount</label>
            <div className="input-group">
              <select
                name="currency"
                className="currency-selector form-control"
                value={formData.currency}
                onChange={handleInputChange}
                required
              >
                {currencies.map((currency) => (
                  <option key={currency.currency_id} value={currency.currency_id}>
                    {currency.code}
                  </option>
                ))}
              </select>
              <input
                type="number"
                name="amount"
                className="form-control"
                placeholder="Amount"
                value={formData.amount}
                onChange={handleInputChange}
                required
              />
            </div>
          </div>
          <div className="form-group">
            <label>Tag</label>
            <select name="tag" className="form-control" value={formData.tag} onChange={handleInputChange} required>
              {tags.map((tag) => (
                <option key={tag.badge_id} value={tag.badge_id}>
                  {tag.name}
                </option>
              ))}
            </select>
          </div>
          <div className="form-group">
            <label>Date & Time</label>
            <input
              type="datetime-local"
              name="timestamp"
              className="form-control"
              value={formData.timestamp}
              onChange={handleInputChange}
              required
            />
          </div>
          <div className="form-group">
            <label>Description</label>
            <textarea
              name="description"
              className="form-control"
              placeholder="Description"
              value={formData.description}
              onChange={handleInputChange}
              required
            ></textarea>
          </div>
          <button type="submit" className="btn btn-primary w-100 mt-3">
            Save
          </button>
        </form>
      </div>
    </div>
  );
};

export default EditExpenseModal;
